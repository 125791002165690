import React, { FunctionComponent } from "react";

import Layout from "../common/layout/layout";
import SEO from "../common/seo";

const NotFoundPage: FunctionComponent = () => (
  <Layout>
    <SEO title="404" />
    <p>Page does not exist. Try clicking on the title and starting over.</p>
  </Layout>
);

export default NotFoundPage;
